import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import { PrimaryColours, SecondaryColours, PromotionalColours, BaseColours, ExtendedColours, MonochromeColours, SemanticColours, ColourLookup } from '@components/design-tokens';
import FurtherReading from '@shared/colours/further-reading';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-palettes"
            }}>{`Colour palettes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-accessibility"
            }}>{`Colour accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "colour-palettes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-palettes",
        "aria-label": "colour palettes permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour palettes`}</h2>
    <p>{`Tu’s selection of colours are divided into distinct palettes. The available palettes are Primary, Secondary, Campaign, Semantic and Monochrome.`}</p>
    <h3>{`Primary palette`}</h3>
    <p>{`Tu’s core colours are white and black. The black is the colour associated with Tu by users, so it’s important to use this colour first and foremost.`}</p>
    <h4>{`Primary base colours`}</h4>
    <GridList columns="2" mdxType="GridList">
  <ColourBlock fill="#000000" mdxType="ColourBlock">Primary Black Base</ColourBlock>
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Primary White Base</ColourBlock>
    </GridList>
    <h4>{`Primary extended colours`}</h4>
    <h5>{`Primary Black extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#404040" mdxType="ColourBlock">Primary Black Light</ColourBlock>
    </GridList>
    <h5>{`Primary White extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#F2F2F2" mdxType="ColourBlock">Primary White Dark</ColourBlock>
    </GridList>
    <h3>{`Secondary palette`}</h3>
    <p>{`Tu’s secondary palette revolves around the colour green. It can can be used to show value around certain products or as an to the accent the primary palette.`}</p>
    <h4>{`Secondary base colours`}</h4>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#4CA585" mdxType="ColourBlock">Value Green Base</ColourBlock>
    </GridList>
    <h4>{`Secondary extended colours`}</h4>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#78BAA2" mdxType="ColourBlock">Value Green Tint 75</ColourBlock>
  <ColourBlock fill="#98C4B4" mdxType="ColourBlock">Value Green Tint 50</ColourBlock>
  <ColourBlock fill="#BFD5CD" mdxType="ColourBlock">Value Green Tint 25</ColourBlock>
    </GridList>
    <h3>{`Campaign palette`}</h3>
    <p>{`Tu uses a campaign palette that allows flexibility and consistency across campaigns. The campaign palette can be used to accent products featured in a collection.`}</p>
    <h4>{`Campaign base colours`}</h4>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#FDC533" mdxType="ColourBlock">Denim Base</ColourBlock>
  <ColourBlock fill="#6787C1" mdxType="ColourBlock">Back To School Base</ColourBlock>
  <ColourBlock fill="#E6655D" mdxType="ColourBlock">Lingerie Base</ColourBlock>
  <ColourBlock fill="#8EC9AB" mdxType="ColourBlock">Summer Of Love Yourself Base</ColourBlock>
  <ColourBlock fill="#192A44" mdxType="ColourBlock">Work It Base</ColourBlock>
    </GridList>
    <h4>{`Campaign extended colours`}</h4>
    <h5>{`Denim extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#FDD366" mdxType="ColourBlock">Denim Tint 75</ColourBlock>
  <ColourBlock fill="#FEE299" mdxType="ColourBlock">Denim Tint 50</ColourBlock>
  <ColourBlock fill="#FFF0CC" mdxType="ColourBlock">Denim Tint 25</ColourBlock>
    </GridList>
    <h5>{`Back To School extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#8DA5D0" mdxType="ColourBlock">Back To School Tint 75</ColourBlock>
  <ColourBlock fill="#B3C3E0" mdxType="ColourBlock">Back To School Tint 50</ColourBlock>
  <ColourBlock fill="#D9E1EF" mdxType="ColourBlock">Back To School Tint 25</ColourBlock>
    </GridList>
    <h5>{`Lingerie extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#EC8B85" mdxType="ColourBlock">Lingerie Tint 75</ColourBlock>
  <ColourBlock fill="#F3B2AE" mdxType="ColourBlock">Lingerie Tint 50</ColourBlock>
  <ColourBlock fill="#F9D9D7" mdxType="ColourBlock">Lingerie Tint 25</ColourBlock>
    </GridList>
    <h5>{`Summer Of Love Yourself extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#AAD6C0" mdxType="ColourBlock">Summer Of Love Yourself Tint 75</ColourBlock>
  <ColourBlock fill="#C7E4D5" mdxType="ColourBlock">Summer Of Love Yourself Tint 50</ColourBlock>
  <ColourBlock fill="#E3F1EA" mdxType="ColourBlock">Summer Of Love Yourself Tint 25</ColourBlock>
    </GridList>
    <h5>{`Work It extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#535F73" mdxType="ColourBlock">Work It Tint 75</ColourBlock>
  <ColourBlock fill="#8C94A1" mdxType="ColourBlock">Work It Tint 50</ColourBlock>
  <ColourBlock fill="#C5CAD0" mdxType="ColourBlock">Work It Tint 25</ColourBlock>
    </GridList>
    <h3>{`Monochrome palette`}</h3>
    <p>{`The monochome palette is a group of colours that can provide text contrast and neutrality to designs.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#000000" mdxType="ColourBlock">Monochrome Black</ColourBlock>
  <ColourBlock fill="#262626" mdxType="ColourBlock">Monochrome Darker</ColourBlock>
  <ColourBlock fill="#404040" mdxType="ColourBlock">Monochrome Dark</ColourBlock>
  <ColourBlock fill="#737373" mdxType="ColourBlock">Monochrome Medium</ColourBlock>
  <ColourBlock fill="#D8D8D8" mdxType="ColourBlock">Monochrome Light</ColourBlock>
  <ColourBlock fill="#F2F2F2" mdxType="ColourBlock">Monochrome Lighter</ColourBlock>
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Monochrome White</ColourBlock>
    </GridList>
    <h3>{`Semantic palette`}</h3>
    <p>{`The semantic palette is a group of colours to communicate key messages throughout an experience. Use red for error messages, green for success messages, blue for important information, and yellow for warnings.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#D50000" mdxType="ColourBlock">Error Base</ColourBlock>
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Information Base</ColourBlock>
  <ColourBlock fill="#2E7D32" mdxType="ColourBlock">Success Base</ColourBlock>
  <ColourBlock fill="#FFD600" mdxType="ColourBlock">Warning Base</ColourBlock>
    </GridList>
    <h4>{`Semantic extended colours`}</h4>
    <h5>{`Error extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#A50000" mdxType="ColourBlock">Error Darker</ColourBlock>
  <ColourBlock fill="#BD0000" mdxType="ColourBlock">Error Dark</ColourBlock>
  <ColourBlock fill="#DD2E2E" mdxType="ColourBlock">Error Light</ColourBlock>
  <ColourBlock fill="#FBE6E6" mdxType="ColourBlock">Error Lighter</ColourBlock>
    </GridList>
    <h5>{`Success extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#246127" mdxType="ColourBlock">Success Darker</ColourBlock>
  <ColourBlock fill="#296F2C" mdxType="ColourBlock">Success Dark</ColourBlock>
  <ColourBlock fill="#549457" mdxType="ColourBlock">Success Light</ColourBlock>
  <ColourBlock fill="#EAF2EB" mdxType="ColourBlock">Success Lighter</ColourBlock>
    </GridList>
    <h5>{`Information extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#135CA3" mdxType="ColourBlock">Information Darker</ColourBlock>
  <ColourBlock fill="#1669BA" mdxType="ColourBlock">Information Dark</ColourBlock>
  <ColourBlock fill="#428FDA" mdxType="ColourBlock">Information Light</ColourBlock>
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">Information Lighter</ColourBlock>
    </GridList>
    <h5>{`Warning extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#C6A600" mdxType="ColourBlock">Warning Darker</ColourBlock>
  <ColourBlock fill="#E2BE00" mdxType="ColourBlock">Warning Dark</ColourBlock>
  <ColourBlock fill="#FFDD2E" mdxType="ColourBlock">Warning Light</ColourBlock>
  <ColourBlock fill="#FFFBE6" mdxType="ColourBlock">Warning Lighter</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "colour-accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-accessibility",
        "aria-label": "colour accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour accessibility`}</h2>
    <p>{`To comply with the Web Content Accessibility Guidelines AA standard contrast ratios, choose colours from the Tu palette that have sufficient color contrast between them so that people with low vision can see and use our products.`}</p>
    <h3>{`Secondary palette accessibility`}</h3>
    <p>{`When using any of the Value Green colours as a background colour, only use Monochrome Black (#000000) or Monochrome Darker (#262626) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#4CA585" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#78BAA2" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#98C4B4" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#BFD5CD" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#4CA585" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
    <ColourBlock fill="#78BAA2" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
    <ColourBlock fill="#98C4B4" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
    <ColourBlock fill="#BFD5CD" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <h3>{`Campaign palette accessibility`}</h3>
    <p>{`When using Denim Base (#FDC533), Back To School Base (#6787C1), Lingerie Base (#E6655D) or Summer Of Love Yourself Base (#8EC9AB) as a background colour, only use Monochrome Black (#000000) or Monochrome Darker (#262626) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#FDC533" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#6787C1" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#E6655D" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#8EC9AB" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#FDC533" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
    <ColourBlock fill="#6787C1" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
    <ColourBlock fill="#E6655D" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
    <ColourBlock fill="#8EC9AB" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using any of the extended colours of Denim Base (#FDC533), Back To School Base (#6787C1), Lingerie Base (#E6655D) or Summer Of Love Yourself Base (#8EC9AB) as a background colour, only use Monochrome Black (#000000) or Monochrome Darker (#262626) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#FFF0CC" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#D9E1EF" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#F9D9D7" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#E3F1EA" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#FFF0CC" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
    <ColourBlock fill="#D9E1EF" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
    <ColourBlock fill="#F9D9D7" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
    <ColourBlock fill="#E3F1EA" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Work It Base (#192A44) as a background colour, only use Monochrome White (#FFFFFF), Monochrome Lighter (#F2F2F2) or Monochrome Light (#D8D8D8) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#192A44" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }}>
        Monochrome White
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#192A44" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Work It Tint 75 (#535F73) as a background colour, use Monochrome White (#FFFFFF) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#535F73" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }}>
        Monochrome White
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#535F73" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Work It Tint 50 (#8C94A1) or Work It Tint 25 (#C5CAD0) as a background colour, use Monochrome Black (#000000) or Monochrome Darker (#262626) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#8C94A1" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
    <ColourBlock fill="#C5CAD0" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.darker}`
          }}>
        Monochrome Darker
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#8C94A1" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
    <ColourBlock fill="#C5CAD0" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <hr></hr>
    <FurtherReading mdxType="FurtherReading" />
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      